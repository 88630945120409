import {
  Footer,
  VisualArea,
  ButtonEnter,
  ContentArea,
  NotesArea
} from '@components'
import {
  CAMPAIGN_TYPE_HAVE_SERIAL_CODE,
  CAMPAIGN_IS_EXPIRED
} from '@constants'
import { Campaign } from '@utils'

export default {
  name: 'CampaignHome',
  props: {
    campaignUrl: String,
    campaignUrlName: String,
    campaignInfos: Object,
    campaignNotes: Array,
    campaignSettings: Object
  },
  components: {
    Footer,
    VisualArea,
    ButtonEnter,
    ContentArea,
    NotesArea
  },
  data() {
    return {
      userData: {},
      openedCollapse: 0,
      utmCampaign: '',
      utmMedium: '',
      utmSource: '',
      isPushOtherRoute: false
    }
  },
  methods: {
    notesAreaOpen(value) {
      // Update status of Notes collapse
      this.openedCollapse = value

      return
    }
  },
  computed: {
    createSetAppContainerStyles() {
      if (this.campaignSettings) {
        const objStyles = {
          '--app-container-bg-color': this.campaignSettings.preset_bg_color,
          '--app-container-text-color':
                this.campaignSettings.preset_text_color,
          '--app-container-font-family': Campaign.getLanguage().font_type
        }
        this.$setAppStyles(objStyles)
      }
    }
  },
  created() {
    // Get utm value
    this.utmCampaign = this.$route.query.utm_campaign
    this.utmMedium = this.$route.query.utm_medium
    this.utmSource = this.$route.query.utm_source
    // Check it's Serial Code and redirect to Serial Code Auth || Campaign is Redemption Campaign || Campaign is Great Eastern
    if (
      (this.$route.query.code &&
      CAMPAIGN_TYPE_HAVE_SERIAL_CODE.includes(this.campaignInfos.campaign_type))
      || (this.campaignInfos?.is_redemption_campaign && this.campaignInfos?.is_expired != CAMPAIGN_IS_EXPIRED)
      || (this.campaignInfos?.is_great_eastern_campaign && this.campaignInfos?.is_expired != CAMPAIGN_IS_EXPIRED)
    ) {
      this.isPushOtherRoute = true;
      return this.$router.push({
        name: 'campaign.auth.serial',
        query: {
          code: this.$route.query.code,
          utm_campaign: this.utmCampaign,
          utm_medium: this.utmMedium,
          utm_source: this.utmSource
        }
      })
    }
  }
}
